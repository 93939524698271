const SkillsList = [{
        title: 'FE Development',
        skills: [
            'React/Redux, ',
            'Angular 1.4-7, ',
            'JavaScript/ES6, ',
            'Typescript, ',
            'RxJS, ',
            'jQuery, ',
            'D3.js, ',
            'AG-Grid, ',
            'Webpack',
        ],
        imageClass: 'code-icon',
        imageAlt: 'web browser with code symbols',
    },
    {
        title: 'BE Development',
        skills: [
            'Node.js, ',
            'Java, ',
            'RabbitMQ, ',
            'JOOQ, ',
            'Express, ',
            'MySQL, ',
            'PostgreSQL, ',
            'RethinkDB, ',
            'Liquibase, ',
            'Knex'
        ],
        imageClass: 'be-icon',
        imageAlt: 'web browser with back end code symbols',
    },
    {
        title: 'Testing',
        skills: [
            'JUnit, ',
            'Mockito, ',
            'Jasmine, ',
            'Mocha, ',
            'Chai, ',
            'Enzyme, ',
            'Selenium, ',
            'Protractor',
        ],
        imageClass: 'testing-icon',
        imageAlt: 'computer monitor with tick inside screen',
    },
    {
        title: 'Workflow',
        skills: [
            'TDD, ',
            'Git, ',
            'NPM, ',
            'Github, ',
            'GitLab, ',
            'Agile, ',
            'Jira, ',
            'Trello, ',
            'Heroku',
        ],
        imageClass: 'workflow-icon',
        imageAlt: 'tree graph showing workflow',
    },
    {
        title: 'Web Design',
        skills: [
            'HTML5, ',
            'CSS3, ',
            'SASS, ',
            'ARIA, ',
            'Responsive Design, ',
            'Material Design, ',
            'Adobe Photoshop, ',
            'Adobe Illustrator'
        ],
        imageClass: 'design-icon',
        imageAlt: 'painters palette',
    },
];

export default SkillsList;
